<template>
  <v-app>
    <v-dialog v-model="isLoading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-main class="background">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
};
</script>

<style scoped>
.background {
  background-color: #eee;
  width: 100%;
  height: 100%;
  background-size: cover;
}
</style>